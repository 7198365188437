import axios from 'axios'
import { writeEvaRecord } from '../util/facades/device-facade'
import debug from 'debug'
// import { resolve } from 'core-js/fn/promise'
const log = debug('xpedeo-core:eva')
debug.enable(process.env.VUE_APP_DEBUG)

/* Creates a new queue. A queue is a first-in-first-out (FIFO) data structure -
 * items are added to the end of the queue and removed from the front.
 */
function Queue () {
  // initialise the queue and offset
  let queue = []
  let offset = 0

  // Returns the length of the queue.
  this.getLength = function () {
    return (queue.length - offset)
  }

  // Returns true if the queue is empty, and false otherwise.
  this.isEmpty = function () {
    return (queue.length === 0)
  }

  /* Enqueues the specified item. The parameter is:
   *
   * item - the item to enqueue
   */
  this.enqueue = function (item) {
    queue.push(item)
  }

  /* Dequeues an item and returns it. If the queue is empty, the value
   * 'undefined' is returned.
   */
  this.dequeue = function () {
    // if the queue is empty, return immediately
    if (queue.length === 0) return undefined

    // store the item at the front of the queue
    const item = queue[offset]

    // increment the offset and remove the free space if necessary
    if (++offset * 2 >= queue.length) {
      queue = queue.slice(offset)
      offset = 0
    }

    // return the dequeued item
    return item
  }

  /* Returns the item at the front of the queue (without dequeuing it). If the
   * queue is empty then undefined is returned.
   */
  this.peek = function () {
    return (queue.length > 0 ? queue[offset] : undefined)
  }
}

const evaMediaDirective = {
  bind: function (el, binding, vnode) {
    let maxProgress = 0
    let recordedTime = null
    let currentMediaPageId = null
    let currentBinId = null
    vnode.componentInstance.$on('media-progress-changed', () => {
      log('v-eva-media.media-progress-changed')
      if (maxProgress < vnode.componentInstance.progress) {
        maxProgress = vnode.componentInstance.progress
      }
      if (currentMediaPageId === null) {
        currentMediaPageId = vnode.componentInstance.$route.params.id
        log('v-eva-media.media-progress-changed setting pageId ', currentMediaPageId)
      }
      if (currentBinId === null) {
        currentBinId = vnode.componentInstance.binaryId
        log('v-eva-media.media-progress-changed setting binId ', currentBinId)
      }
    })
    vnode.componentInstance.$on('media-playing', () => {
      log('v-eva-media.media-playing')
      if (recordedTime === null) {
        recordedTime = vnode.componentInstance.$xp.eva.timeStampSeconds()
      }
    })
    vnode.componentInstance.$on('media-pause', () => {
      log('v-eva-media.media-pause')
      // writeMediaRecord(vnode)
    })
    vnode.componentInstance.$on('media-session-ended', () => {
      log('v-eva-media.media-session-ended')
      log('session ended')
      if (currentMediaPageId === null) {
        currentMediaPageId = vnode.componentInstance.$route.params.id
        log('v-eva-media.media-session-ended setting pageId ', currentMediaPageId)
      }
      if (currentBinId === null) {
        currentBinId = vnode.componentInstance.binaryId
        log('v-eva-media.media-session-ended setting binId ', currentBinId)
      }
      const mediaRecord = {
        start: recordedTime,
        end: vnode.componentInstance.$xp.eva.timeStampSeconds(),
        complete: Math.round(maxProgress * 100),
        langcode: vnode.componentInstance.locale,
        pageid: currentMediaPageId,
        binid: currentBinId
      }
      log('mediarec complete', mediaRecord)
      if (mediaRecord.start !== null && mediaRecord.start !== undefined && mediaRecord.end != null && mediaRecord.end !== undefined) {
        vnode.componentInstance.$xp.eva.addMedia(mediaRecord)
      }
    })
  }
}
// These consts are for (browser, user-app) versions, not used on Loan-Devices.
const minimumPartialPacket = 500// 00 // in Bytes
const maximumFailTimes = 10 // Number of re-send calls when axios fails
Storage.prototype.setObj = function (key, obj) {
  return this.setItem(key, JSON.stringify(obj))
}
Storage.prototype.getObj = function (key) {
  return JSON.parse(this.getItem(key))
}

export default {
  _stats: {
    vstart: null,
    vend: null,
    pages: [],
    media: [],
    geo: [],
    deviceId: null,
    platform: null
  },
  deviceTimeOffset: 0,
  statsChunksQueue: new Queue(),
  failTimes: 0,
  isSendingChunks: false,
  __$xp: null,
  resetStats () {
    this._stats = {
      vstart: this._stats.vstart,
      vend: null,
      pages: [],
      media: [],
      geo: [],
      deviceId: this._stats.deviceId,
      platform: this._stats.platform
    }
  },
  resetQueue () {
    this.statsChunksQueue = new Queue()
  },
  startVisit () {
    log('startVisit(), resetting Record.')
    if (this.__$xp.device.isLoan) {
      this._stats.platform = this.__$xp.device.platform + '-loan'
      this.deviceTimeOffset = this.__$xp.device.getDeviceTimeOffset()
    } else {
      this._stats.platform = this.__$xp.device.platform
    }
    this._stats.vstart = this.timeStampSeconds()
    this._stats.deviceId = this.__$xp.device.deviceId
    log('setting device id', this._stats.deviceId)
    log('Visit Started, DeviceId: ', this._stats.deviceId, ' Platform: ', this._stats.platform)
  },
  endVisit () {
    throw new Error('calling eva.endVisit() before installing the plugin')
  },
  addPage (data) {
    data.start = this.millisToSecondsOffset(data.start)
    data.end = this.millisToSecondsOffset(data.end)
    data.on = Math.round(data.on / 1000)
    data.off = Math.round(data.off / 1000)
    this._stats.pages.push(JSON.parse(JSON.stringify(data)))
    if (!this.__$xp.device.isLoan) {
      this.addChunkToQueue()
    }
  },
  addMedia (data) {
    this._stats.media.push(JSON.parse(JSON.stringify(data)))
    if (!this.__$xp.device.isLoan) {
      this.addChunkToQueue()
    }
  },
  addGeoInfo (data) {
    data.start = this.millisToSecondsOffset(data.start)
    data.duration = Math.round(data.duration / 1000)
    this._stats.geo.push(JSON.parse(JSON.stringify(data)))
    if (!this.__$xp.device.isLoan) {
      // this.sendPartialRecord()
      this.addChunkToQueue()
    }
  },
  addChunkToQueue () {
    // this._stats.
    if (this._stats.deviceId === null) {
      if (this.__$xp.device.deviceId === null) {
        this.__$xp.device.getUuid().then(() => {
          this._stats.deviceId = this.__$xp.device.deviceId
        })
      } else { this._stats.deviceId = this.__$xp.device.deviceId }
    }
    const evaRecord = JSON.stringify(this._stats)
    const recByteSize = this.lengthInUtf8Bytes(evaRecord)
    log('Chunk size :', recByteSize, ' minimum is ', minimumPartialPacket)
    if (this._stats.vend === null && recByteSize < minimumPartialPacket) {
      return
    }
    log('add eva stats Chunk To Queue', this.failTimes, this.isSendingChunks)
    log('adding to queue', this.statsChunksQueue.getLength())
    this.statsChunksQueue.enqueue(evaRecord)
    log('added to queue', this.statsChunksQueue.getLength())
    this.resetStats()
    if (this.failTimes === 0 && !this.isSendingChunks) {
      this.isSendingChunks = true
      this.sendChunks()
        .then(() => log('in addChunkToQueue, Chunk sent', 'this.statsChunksQueue', this.statsChunksQueue.getLength()))
        .catch((e) => { log('error', e) }
        )
    }
  },
  async sendChunks () {
    // while (!this.statsChunksQueue.isEmpty() && this.failTimes < maximumFailTimes) {
    const recToSend = this.statsChunksQueue.dequeue()
    try {
      await this.postRecord(recToSend).then(() => {
        log('postrecord then, should be posted successfully', this.statsChunksQueue.getLength())
        this.failTimes = 0
        // this.statsChunksQueue.dequeue()
        if (this.statsChunksQueue.getLength() > 0) {
          log('still sending chunks')
          this.sendChunks()
        } else {
          log('finished sending chunks', this.statsChunksQueue.getLength())
          this.isSendingChunks = false
        }
      })
    } catch (e) {
      this.statsChunksQueue.enqueue(recToSend)
      if (this.failTimes >= maximumFailTimes) {
        // setTimeout(() => {
        // log('')
        this.failTimes = 0
        this.isSendingChunks = false
        // }, 1000)
        // break
        return
      }
      log('Failed to send eva-stats chunk, error :', e)
      this.failTimes++
      setTimeout(() => {
        this.sendChunks()
      }, 6000)
    }
    // }
    // this.failTimes = 0
  },
  async postRecord (rec) {
    log('in post rec', rec)
    rec = JSON.parse(rec)
    rec.vend = this.timeStampSeconds()
    rec = JSON.stringify(rec)
    log('in post rec', rec, ' and the queue\'s length is is ', this.statsChunksQueue.getLength())
    if (this.__$xp.testing) {
      log('testing, Eva is disabled')
      return
    }
    await axios.post(window.XPEDEO_SETTINGS.content.remoteUrl + 'eva/record/', rec)
  },
  lengthInUtf8Bytes (str) {
    // returns the byte length of a utf8 string
    let s = str.length
    for (let i = str.length - 1; i >= 0; i--) {
      const code = str.charCodeAt(i)
      if (code > 0x7f && code <= 0x7ff) s++
      else if (code > 0x7ff && code <= 0xffff) s += 2
      if (code >= 0xDC00 && code <= 0xDFFF) i-- // trail surrogate
    }
    return s
  },

  timeStampSeconds () {
    if (process.env.VUE_APP_IS_LOAN) {
      return Math.round((Date.now() + this.deviceTimeOffset) / 1000)
    } else {
      return Math.round(Date.now() / 1000)
    }
  },

  millisToSecondsOffset (millies) {
    if (process.env.VUE_APP_IS_LOAN) {
      return Math.round((millies + this.deviceTimeOffset) / 1000)
    } else {
      return Math.round(millies / 1000)
    }
  },

  calcWithOffset (num) {
    if (process.env.VUE_APP_IS_LOAN) {
      return num + this.deviceTimeOffset
    } else {
      return num
    }
  },
  timeStampMillis () {
    if (process.env.VUE_APP_IS_LOAN) {
      return Date.now() + this.deviceTimeOffset
    } else {
      return Date.now()
    }
  },
  checkCookie () {
    if (document.cookie.includes('additor-eva-disable')) {
      return true
    } else {
      return false
    }
  },
  install (Vue, opts, $xp) {
    if (this.checkCookie()) {
      log('disabled eva in additor session')
      $xp.testing = true
    }
    if ($xp.evaInstalled) { return }
    Vue.directive('eva-media', evaMediaDirective)
    this.endVisit = () => {
      log('endVisit()')
      this._stats.vend = this.timeStampSeconds()
      log(this._stats.vend)
      if ($xp.device.isLoan) {
        log('platform:', this._stats.platform)
        try {
          writeEvaRecord(JSON.stringify(this._stats))
          log('writeEvaRecord() on load device was successful')
          this.resetStats()
        } catch (error) {
          log('writeEvaRecord() error', error)
        }
      } else {
        if (this._stats.pages.length + this._stats.media.length + this._stats.geo.length !== 0) {
          log('should not be empty', this._stats.pages.length)
          const evaRecord = JSON.stringify(this._stats)
          log(evaRecord)
          this.statsChunksQueue.enqueue(evaRecord)
        }
        this.resetStats()
        if (!this.isSendingChunks && !this.statsChunksQueue.isEmpty()) {
          this.sendChunks()
        }
      }
    }
    $xp.evaInstalled = true
    Vue.util.defineReactive($xp, 'eva', this)
    this.__$xp = $xp
  }
}
